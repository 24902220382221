<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { translate } from "@telia/b2b-i18n";
import { addNewToast } from "@telia/b2b-message-service";

import useDcpState from "../composables/dcp-state";

import { translateMixin } from "../locale";

import { deleteNotification } from "../services/corp-customer-datacom-messaging";

import { logError } from "../utils/log-error";
import {
  trackCloseRemoveServiceModal,
  trackCompletedRemoveNotificationService,
  trackErrorRemoveNotificationService,
  trackInitatedRemoveNotificationService,
  trackOpenRemoveNotificationServiceModal,
} from "../utils/analytics";

const props = defineProps<{
  id: number;
  open: boolean;
}>();

const emit = defineEmits<{ (e: "closeModal"): void }>();

const t = translateMixin.methods.t;
const isModalOpen = ref(false);
const notificationId = ref(0);
const toastId = ref(0);
const hasError = ref(false);
const isSubmitting = ref(false);
const { scopeId, tscid, tryToFetchNotificationsList } = useDcpState();

const header = computed(() => {
  return hasError.value
    ? t("mybusiness.error.heading")
    : t("mybusiness.datacom_notification_remove_service_modal_header");
});

const content = computed(() => {
  return hasError.value
    ? t("removeNotificationServiceModal.errorText")
    : t("mybusiness.datacom_notification_remove_service_modal_content");
});

const closeRemoveServiceModal = (): void => {
  isModalOpen.value = false;
  trackCloseRemoveServiceModal();
  emit("closeModal");
};

const removeNotificationService = (): void => {
  _tryToRemoveNotificationService();
};

const _tryToRemoveNotificationService = async (): Promise<void> => {
  try {
    hasError.value = false;
    isSubmitting.value = true;

    trackInitatedRemoveNotificationService();

    await deleteNotification(scopeId.value, tscid.value, props.id);

    trackCompletedRemoveNotificationService();

    tryToFetchNotificationsList();
    notificationId.value = 0;
    isModalOpen.value = false;
    emit("closeModal");

    addNewToast(
      `removed-service-${toastId.value++}`,
      "info",
      translate("mybusiness.datacom_notification_service_removed_header"),
      ""
    );
  } catch (error) {
    trackErrorRemoveNotificationService();
    logError("Could not remove notification service");
    hasError.value = true;
  } finally {
    isSubmitting.value = false;
  }
};

watch(
  () => props.open,
  () => {
    if (!props.open) return;

    hasError.value = false;
    notificationId.value = props.id;
    isModalOpen.value = true;
    trackOpenRemoveNotificationServiceModal();
  }
);
</script>

<template>
  <b2x-modal
    t-id="remove-notification-modal"
    :is-open="isModalOpen"
    @closeModal="closeRemoveServiceModal"
    :show-close-button="false"
  >
    <div slot="modal-body">
      <telia-heading variant="title-300" tag="h3" t-id="heading">{{ header }}</telia-heading>
      <telia-p t-id="message">{{ content }}</telia-p>
    </div>
    <div slot="button-footer" class="button-footer">
      <template v-if="hasError">
        <telia-button
          variant="primary"
          @click="closeRemoveServiceModal"
          t-id="agree-button"
          type="button"
          >{{ t("mybusiness.GOT_IT") }}</telia-button
        >
      </template>
      <template v-if="!hasError">
        <telia-button
          variant="tertiary-purple"
          @click="closeRemoveServiceModal"
          t-id="abort-button"
          type="button"
          >{{ t("mybusiness.cancel") }}</telia-button
        >
        <b2x-loading-button
          t-id="remove-button"
          variant="primary"
          type="button"
          :loading-label="t('removeNotificationServiceModal.buttons.submitting')"
          :is-loading="isSubmitting"
          @click="removeNotificationService"
          >{{ t("removeNotificationServiceModal.buttons.submit") }}</b2x-loading-button
        >
      </template>
    </div>
  </b2x-modal>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/typography/variables.scss";

div.modal-body {
  padding: $telia-spacing-24;
  telia-heading {
    padding-bottom: $telia-spacing-12;
  }
}
div.button-footer {
  display: flex;
  justify-content: flex-end;
  padding: $telia-spacing-24;

  telia-button + telia-button {
    margin-left: 1rem;
  }
}
</style>
